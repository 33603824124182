//variables
//var variable = $(".element").funkce();

//onLoad
$(function() {
    $("#main-carousel").owlCarousel({
      items: 1,
      loop: true,
      nav: true,
      dots: true,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true   
    });

		$('.product-view__image').BlackAndWhite({
		    hoverEffect : true, // default true
		    // set the path to BnWWorker.js for a superfast implementation
		    webworkerPath : false,
		    // to invert the hover effect
		    invertHoverEffect: false,
		    // this option works only on the modern browsers ( on IE lower than 9 it remains always 1)
		    intensity:1,
		    // this option enables/disables the attribute crossorigin=anonymous on image tags. Default true.
		    // please refer to https://github.com/GianlucaGuarini/jQuery.BlackAndWhite#important
		    crossOrigin: true,
		    speed: { //this property could also be just speed: value for both fadeIn and fadeOut
		        fadeIn: 200, // 200ms for fadeIn animations
		        fadeOut: 800 // 800ms for fadeOut animations
		    },
		    onImageReady:function(img) {
		        // this callback gets executed anytime an image is converted
		    }
		});


});


	if ($('.nav-pills .navbar-toggle').is(':hidden')) {
		$('#products > ul').affix({
		  offset: {
		    top: function () {
	      		var sliderHeight = $( ".section--slider" ).height();
				var prnavOffset = sliderHeight + 40;
		    	return prnavOffset
		    },
		    bottom: function () {
		    	var footerHeight = $( ".footer" ).outerHeight( true ) + 20;
		    	return footerHeight
		    }
		  }
		})
	}
	$( window ).resize(function() {
		if ($('.nav-pills .navbar-toggle').is(':hidden')) {
		    sliderHeight = $( ".section--slider" ).height();
			prnavOffset = sliderHeight + 40;
		    footerHeight = $( ".footer" ).outerHeight( true ) + 20;
		    if( $('#products > ul').length > 0 ) {
		      $('#products > ul').data('bs.affix').options.offset.top = prnavOffset;
				  $('#products > ul').data('bs.affix').options.offset.bottom = footerHeight;
		    }
		} else {
			$(window).off('.affix')
			$('.nav-pills').removeData('affix').removeClass('affix affix-top affix-bottom')
		}
  	});	